import React from "react";


import Layout from '../components/layout';
import AccessoriesDetails from '../components/accessoriesDetails';

import SEO from '../components/seo';
import '../components/style.scss';
import '../styles/accessoriesStyles.css';


const PhoneDetailsPage = ({ pageContext: context, location }) => {
    var serviceCity;
    if (location.state){
        serviceCity = location.state.serviceCity;
    }
    else {
        serviceCity = "Portland, OR";
    }

    return(
        <Layout serviceCity={serviceCity} uniqueHeader={'Buy ' + context.product.name + ' in ' + serviceCity}>
            <SEO 
            title={ `${context.product.name}`} 
            description={`If you are looking to buy ${context.product.name}, you will easily find it in our store.`}/>
            <AccessoriesDetails product={context.product}/>
        </Layout>
    );
}
export default PhoneDetailsPage;