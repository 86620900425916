import React, { useState } from 'react';

import './style.scss';
import '../styles/styles.css';
import '../styles/accessoriesStyles.css'

import googlePixel from '../data/google-pixel.json';
import iPads from '../data/ipads.json';
import iPhones from '../data/iphones.json';
import LgPhones from '../data/lg.json';
import Motorola from '../data/motorola.json';
import Samsung from '../data/samsung.json';
import { Link } from 'gatsby';


const AccessoriesDetails = (props) => {

    var [avlIsOpen, setAvlIsOpen] = useState(false);
    var [avlIsOpenText, setAvlIsOpenText] = useState('Show more...');

    const toggleOpen = () =>{
        setAvlIsOpen(!avlIsOpen);
        setAvlIsOpenText(avlIsOpenText === 'Show more...' ? avlIsOpenText='Hide...' : avlIsOpenText = 'Show more...')
    }

    const getAvalibleProducts = (avalible) => {
        const iPadNames = iPads.map((phone) => {return (phone.name)});
        const iPhoneNames = iPhones.map((phone) => {return (phone.name)});
        const appleNames = iPadNames.concat(iPhoneNames);
        const googlePixelNames = googlePixel.map((phone) => {return (phone.name)});
        const LgPhoneNames = LgPhones.map((phone) => {return (phone.name)});
        const MotorolaNames = Motorola.map((phone) => {return (phone.name)});
        const SamsungNames = Samsung.map((phone) => {return (phone.name)});

        switch(avalible){
            case 'All' :
                const allNames = [
                {header:'Apple', arrayName:appleNames, linkTo:'../cell-phone-repair/iphone-repair'},
                {header:'Google Pixel', arrayName:googlePixelNames, linkTo:'../cell-phone-repair/google-pixel-repair'},
                {header:'LG', arrayName:LgPhoneNames, linkTo:'../cell-phone-repair/lg-repair'},
                {header:'Motorola', arrayName:MotorolaNames, linkTo:'../cell-phone-repair/motorola-repair'},
                {header:'Samsung', arrayName:SamsungNames, linkTo:'../cell-phone-repair/samsung-repair'},
                ];
                return (
                    allNames.map((item) => {
                        return (
                            <div key={item.arrayName}>
                                <div><Link to={item.linkTo} className='accessoriesAvalibleLink'>{item.header}</Link></div>
                                {item.arrayName.map((name) => {return ( <span key={name}>{name} |</span> );})}
                                <br/>
                                <br/>
                            </div>
                        );}) );

            case 'Apple' :
                const namesApple = [{header:'Apple', arrayName:appleNames, linkTo:'../cell-phone-repair/iphone-repair'}];
                console.log(namesApple);
                    return (
                        namesApple.map((item) => {
                            return (
                                <div key={item.arrayName}>
                                    <div><Link to={item.linkTo} className='accessoriesAvalibleLink'>{item.header}</Link></div>
                                    {item.arrayName.map((name) => {return ( <span key={name}>{name} |</span> );})}
                                    <br/>
                                    <br/>
                                </div>
                            );}));
            
            case 'Phones' :
                const phoneNames = [
                    {header:'Google Pixel', arrayName:googlePixelNames, linkTo:'../cell-phone-repair/google-pixel-repair'},
                    {header:'LG', arrayName:LgPhoneNames, linkTo:'../cell-phone-repair/lg-repair'},
                    {header:'Motorola', arrayName:MotorolaNames, linkTo:'../cell-phone-repair/motorola-repair'},
                    {header:'Samsung', arrayName:SamsungNames, linkTo:'../cell-phone-repair/samsung-repair'},
                    ];
                    return (
                        phoneNames.map((item) => {
                            return (
                                <div key={item.arrayName}>
                                    <div><Link to={item.linkTo} className='accessoriesAvalibleLink'>{item.header}</Link></div>
                                    {item.arrayName.map((name) => {return ( <span key={name}>{name} |</span> );})}
                                    <br/>
                                    <br/>
                                </div>
                            );}));
            default:
                return "Most types of electro car";
        }
    }

    return(
        <div>
            <div className='accessoriesOpenWindow'>
                <div className='accessoriesWindowInfoWrapper'>
                    <div className='accessoriesWindowTextWrapper'>
                        <h2 className='accessoriesWindowName'>{props.product.name}
                        </h2>
                        <hr />
                        <div className='accessoriesWindowDetailsWrapper'>
                            <h3 className='accessoriesWindowDetailsHeader'>DETAILS</h3>
                            <ul className='accessoriesWindowDetailsList'>
                                {props.product.details.map((product) => {
                                        return (
                                            <li key={product} className='accessoriesWindowDetailsListItem'>{product}</li>
                                        );})}
                            </ul>
                        </div>
                    </div>
                    <div className='accessoriesWindowImgWrapper'>
                        <img src={props.product.imageUrl} className='accessoriesWindowImg' alt="Accessoire"/>
                    </div>
                </div>
            </div>
            <hr className='accessoriesAvalibleLine'/>
            <div className={avlIsOpen ? 'accessoriesWindowAvalibleWrapper' : 'accessoriesWindowAvalibleWrapper accessoriesWindowAvalibleTextHidden'}>
            <span className='accessoriesWindowAvalibleHeader'>AVALIBLE FOR: </span>
                <div className='accessoriesWindowAvalibleText'>
                    {getAvalibleProducts(props.product.avalible)}
                </div>
             </div>
             <div className='accessoriesAvalibleShowMoreWrapp'>
                 <div role="button" tabIndex={0} onKeyDown={toggleOpen} className='accessoriesAvalibleShowMoreText' onClick={toggleOpen} >
                     {avlIsOpenText}
                 </div>
             </div>
        </div>
    );
}

export default AccessoriesDetails;